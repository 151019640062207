<template>
  <v-container>
    <h2 class="section-title mt-3 ">{{
        this.t('title')
      }}</h2>
    <v-row justify="center" class="pa-7">
      <v-expansion-panels accordion multiple >
        <v-expansion-panel
          v-for="(question,i) in questions"
          :key="i"
        >
          <v-expansion-panel-header expand-icon="mdi-plus">{{question.title}}</v-expansion-panel-header>
          <v-expansion-panel-content class="mt-2">
            {{question.answer}}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <div>
      <p class="text-center mt-5">{{this.t('haveQuestion')}}</p>
      <p class="text-center"> {{this.t('inTouch')}} <a href="mailto:contact@platforma.life">contact@platforma.life</a></p>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'FAQComponent',
  created () {
    this.price = process.env.VUE_APP_SESSION_PRICE
  },
  computed: {
    questions () {
      return [
        { title: this.t('questionWhy'), answer: this.t('answerWhy') },
        { title: this.t('questionHow'), answer: this.t('answerHow') },
        { title: this.t('questionCriteria'), answer: this.t('answerCriteria') },
        { title: this.t('questionCost'), answer: this.t('answerCost') },
        { title: this.t('questionDuration'), answer: this.t('answerDuration') },
        { title: this.t('questionPayment'), answer: this.t('answerPayment') },
        { title: this.t('questionPaymentDesc'), answer: this.t('answerPaymentDesc') },
        { title: this.t('questionPlans'), answer: this.t('answerPlans') },
        { title: this.t('questionShift'), answer: this.t('answerShift') },
        { title: this.t('questionTherapist'), answer: this.t('answerTherapist') },
        { title: this.t('questionFinish'), answer: this.t('answerFinish') },
        { title: this.t('questionConfidential'), answer: this.t('answerConfidential') }
      ]
    }
  },
  methods: {
    t: function (key) {
      return this.$vuetify.lang.t('$vuetify.main.faq.' + key, this.price)
    }
  }
}
</script>

<style lang="scss">
.v-expansion-panel--active > .v-expansion-panel-header--active .v-expansion-panel-header__icon:not(.v-expansion-panel-header__icon--disable-rotate) .v-icon {
  transform: rotate(-135deg)!important;
}
.v-expansion-panel-header {
  font-size: 1.3rem !important;
  font-weight: 300 !important;
  color: #00A4B7;
}
.theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon{
   color: #00A4B7 !important;
 }

</style>
